import { useField } from 'formik';
import { Form, Label } from 'semantic-ui-react';

interface Props{
    placeholder: string;
    name: string;
    label?: string;
    type?: string; 
}

export default function MyTextInput(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <Form.Input error={meta.touched && !!meta.error} label={props.label}>
            <input {...field} {...props} />
            {meta.touched && meta.error ? 
            (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Input>
    )
}